<template>
    <div class="page">

        <div class="page__header">
            <div class="page__info">
                <div class="page__title">Редактирование</div>
                <div class="page__desc">Отредактируйте поставку</div>
            </div>
        </div>

        <div class="page__content">
            <form v-if="supply !== null" class="form-group" method="POST" action="" @submit.prevent="update()">

                <label for="store_id">Магазин</label>
                <div class="control-group">
                    <select
                            v-model="supply.store_id"
                            id="store_id"
                            class="form-control"
                            required
                            disabled>
                        <option
                                :value="store.id">{{ store.address }}</option>
                    </select>
                </div>
                <br>

                <label for="product_id">Продукт</label>
                <div class="control-group">
                    <select
                            v-model="supply.product_id"
                            id="product_id"
                            class="form-control"
                            required
                            disabled>

                        <option
                                :value="product.id">{{ product.title }}</option>
                    </select>
                </div>
                <br>

                <label for="quantity_ordered">Количество</label>
                <div class="control-group">
                    <div class="input-group">
                        <input
                                v-model="supply.quantity_received"
                                id="quantity_ordered"
                                type="number"
                                class="form-control"
                                required>
                        <div v-if="product != null" class="input-group-append">
                            <span class="input-group-text">{{ product.packaging_type }}</span>
                        </div>
                    </div>
                </div>
                <br>

                <!--<label for="expired_at">Срок годности</label>-->
                <!--<div class="control-group">-->
                    <!--<input-->
                            <!--v-model="expired_at"-->
                            <!--id="expired_at"-->
                            <!--type="datetime-local"-->
                            <!--class="form-control"-->
                            <!--required>-->
                <!--</div>-->
                <!--<br>-->

                <input type="submit" class="btn btn-success btn-large" value="Сохранить">

            </form>
        </div>

    </div>
</template>


<script>
    import { mapActions } from 'vuex'

    export default {
        name: 'StoreSuppliesUpdate',
        computed: {
            supply() {
                return this.$store.state.supplies.supply != null ? this.$store.state.supplies.supply.supply : null
            },
            expired_at: {
                get() {
                    return this.$store.state.supplies.supply != null ? this.$moment(this.$store.state.supplies.supply.supply.expired_at * 1000).format('YYYY-MM-DDTHH:mm') : null
                },
                set(newVal) {
                    this.$store.state.supplies.supply.supply.expired_at = this.$moment(newVal).unix()
                }
            },
            store() {
                return this.$store.state.supplies.supply != null ? this.$store.state.supplies.supply.store : null
            },
            product() {
                return this.$store.state.supplies.supply != null ? this.$store.state.supplies.supply.product : null
            },
        },
        methods: {
            ...mapActions([
                'storeStores',
                'storeSuppliesGet',
                'storeSuppliesUpdate',
            ]),
            async getSupply() {
                await this.storeSuppliesGet({
                    id: this.$route.params.id,
                })
            },
            async update() {
                await this.storeSuppliesUpdate({
                    id: this.supply.id,
                    quantity_received: this.supply.quantity_received,
                    expired_at: this.supply.expired_at
                })
            }
        },
        created() {
            this.getSupply();
        }
    }
</script>